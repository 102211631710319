<template>
  <div class="clearfix">
    <div class="container my-5" >
      <div class="row" style="text-align: justify;">
        <!-- /.col-lg-4 -->
        <div class="col-md-6">
          <p><strong>MEDIDAS ESPECIALIZADAS Y CERTIFICACIONES LIMITADA – MEDICERT LTDA</strong>es un organismo de inspección que trabaja para ofrecer un portafolio amplio de servicios tales como:</p>
          <ul>
            <li>Inspección de instalaciones eléctricas de uso final básicos</li>
            <li>Inspección de instalaciones eléctricas de transformación asociados a uso final.</li>
            <li>Inspección de instalaciones eléctricas de distribución asociados a uso final.</li>
            <li>Inspección de instalaciones eléctricas de uso final para clientes especiales como:
              <ul>
                <li>Ascensores, Montacargas.</li>
                <li>Piscinas</li>
                <li>Bombas contra incendio.</li>
                <li>Sistemas de Emergencia.</li>
                <li>Lugares con alta concentración de personas.</li>
              </ul>
            </li>
            <li>Satisfacer una demanda de servicios a clientes específicos que, por su condición de mercado, necesitan servicios de inspección de calidad similar o superior a las ofrecidas actualmente con los tiempos del proceso y la oportunidad de atención sean cortos y eficaces para disminuir retrasos y pérdida de tiempo en la entrega de proyectos.</li>
            <li>Bajo esta condición, se desarrolla el proyecto de creación del organismo de inspección con la expectativa de iniciar servicios formales de inspección de instalaciones eléctricas una vez se tenga el reconocimiento para tal fin.</li>
          </ul>
        </div>

        <div class="col-md-6">
          <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            indicators
            img-height="480"
          >
            <b-carousel-slide
              img-src="/img/sl/sl21.jpg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="/img/sl/sl22.jpg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="/img/sl/sl23.jpg"
            ></b-carousel-slide>
            <b-carousel-slide
              img-src="/img/sl/sl24.jpg"
            ></b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'Home',
  mounted () {
    this.$store.commit('setActualPage', 'Inicio')
  }
}
</script>

<style>
/* Carousel base class */
.carousel {
    margin-bottom: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    z-index: 10;
    bottom: 3rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
    height: 32rem;
    background-color: #777;
}

.carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
}
</style>
